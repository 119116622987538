import React, { useState } from "react";
import "../styles/Sesion.css";
import Login from "./Login";
import { motion } from "framer-motion";
import Panel from "./Panel";

const Sesion = ({ usuario }) => {

  const [ login, setLogin ] = useState(true);

  return usuario ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Panel usuario={usuario}/>
    </motion.div>
  ) : (
    <>
      <div className="w-100 d-flex justify-content-center align-items-center posicion-sesion bg-light" style={{height: '100%'}}>
        <div className="w-100">
          <motion.div
            className="m-auto sesion"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Login setLogin={setLogin} loginv={login} />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Sesion;
