import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const TablaRecords = ({ titulo, records }) =>{

  const [ pruebaElegida, setPruebaElegida ] = useState('');
  const [ generoElegido, setGeneroElegido ] = useState('');
  const [ piscinaElegida, setPiscinaElegida ] = useState('');
  const [ resultados, setResultados ] = useState([]);

  function sacarPruebas(){
    const auxPruebas = [
      '50 Libre',
      '100 Libre',
      '200 Libre',
      '400 Libre',
      '800 Libre',
      '1500 Libre',

      '50 Espalda',
      '100 Espalda',
      '200 Espalda',

      '50 Pecho',
      '100 Pecho',
      '200 Pecho',

      '50 Mariposa',
      '100 Mariposa',
      '200 Mariposa',

      '100 Combinado Ind.',
      '200 Combinado Ind.',
      '400 Combinado Ind.',
    ];

    return auxPruebas;
  }

  useEffect(()=>{
    if(pruebaElegida === 'todas'){
      if(generoElegido !== '' && piscinaElegida !== ''){
        const auxPruebas = [];
        sacarPruebas().forEach((prue)=>{
          if(records[generoElegido][piscinaElegida][prue]){
            auxPruebas.push(records[generoElegido][piscinaElegida][prue]);
          }
        })
        setResultados(auxPruebas);
      }
    }else{
      if(generoElegido !== '' && piscinaElegida !== '' && pruebaElegida !== ''){
        setResultados([records[generoElegido][piscinaElegida][pruebaElegida]])
      }
    }

  },[pruebaElegida, generoElegido, piscinaElegida])

  return(
    <motion.div
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    transition={{duration: 1}}
    >
      <div className="alert alert-success alert-dismissible fade show pb-1 mb-1" role="alert">
        <h2>RECORDS {titulo}</h2>
      </div>
      <div className='d-flex flex-wrap justify-content-center'>
        <div className="form-floating me-1 mb-1">
          <select name="prueba" id="prueba" className='form-select' style={{maxWidth: '250px'}} onChange={(ev) => setPruebaElegida(ev.target.value)}>
            <option value="">Elige la prueba</option>
            <option value="todas">Todas</option>
            {sacarPruebas().map((prue, indexPrue)=>{
              return <option key={indexPrue} value={prue}>{prue}</option>
            })}
          </select>
          <label htmlFor="prueba">Prueba</label>
        </div>
        <div className="form-floating me-1 mb-1">
          <select className='form-select' name="genero" id="genero" style={{maxWidth: '250px'}} onChange={(ev) => setGeneroElegido(ev.target.value)}>
            <option value="">Elige</option>
            <option value="femenino">Femenino</option>
            <option value="masculino">Masculino</option>
          </select>
          <label htmlFor="genero">Género</label>
        </div>
        <div className="form-floating me-1 mb-1">
          <select className='form-select' name="piscina" id="piscina" style={{maxWidth: '250px'}} onChange={(ev) => setPiscinaElegida(ev.target.value)}>
            <option value="">Elige</option>
            <option value="25">25 mts</option>
            <option value="50">50 mts</option>
          </select>
          <label htmlFor="piscina">Piscina</label>
        </div>
      </div>
      <DataTable value={pruebaElegida === 'todas' ? resultados : resultados.filter((resu)=>{return resu.prueba === pruebaElegida})} selectionMode="single" dragSelection size='small' stripedRows paginator rows={8} rowsPerPageOptions={[8, 10, 25, 50]} emptyMessage="No hay resultados aún." 
      tableStyle={{ minWidth: '50rem', margin: 'auto'}}>
        <Column header="#" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
        <Column field='prueba' header="Prueba" style={{ width: '30px'}}></Column>
        <Column field='deportista' header="Deportista" style={{ width: '30px'}}></Column>
        <Column field='records' header="Tiempo" style={{ width: '30px'}}></Column>
        <Column field='fechaTorneo' header="Fecha Torneo" style={{ width: '30px'}}></Column>
        <Column field='lugar' header="Lugar" style={{ width: '30px'}}></Column>
      </DataTable>
      {titulo === "MUNDIAL" ?(
        <p className='pt-2 pb-0 mb-0' style={{fontSize: '10px'}}>Información extraída de Wikipedia <b>(https://es.wikipedia.org/wiki/Anexo:Plusmarcas_mundiales_de_nataci%C3%B3n)</b>.</p>
      ):(
        <p className='p-2' style={{fontSize: '10px'}}>Información extraída de la Federación Colombiana de Natación <b>(https://fecna.com.co/)</b>.</p>
      )}
    </motion.div>
  )
}

export default TablaRecords;