import React, { useState} from "react";
import { Routes, Route, Link } from "react-router-dom";
import '../styles/Panel.css';
import { cerrarSesion } from "../controllers/configuracionFirebase";
import Administracion from "./Administracion";
import { Sidebar } from 'primereact/sidebar';
import { Avatar } from 'primereact/avatar';
import Afiliados from "./Afiliados";

const Panel = ({ usuario }) => {

  const [ visible, setVisible ] = useState(false);
  const anioAutor = new Date();

  return (
    <>
      <nav className="navbar navbar-dark sticky-top bg-dark" aria-label="breadcrumb">
        <div className="container-fluid">
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <button
                className="navbar-toggler"
                type="button"
                onClick={() => setVisible(true)}
              >
              <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div className="card flex justify-content-center position-relative">
        <Sidebar visible={visible} position="right" onHide={() => setVisible(false)}>
          <ul className="circles" style={{zIndex: -1}}>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <h2 className="text-center position-absolute" style={{top: '18px'}}>¡Bienvenido!</h2>
          <hr className="m-0" />
          <div className="d-flex justify-content-around align-items-center mt-1 mb-1">
            <Avatar className="flex align-items-center justify-content-center mr-2" image={require('../img/img_logo_swimmingT.png')} size="xlarge"/>
            <span style={{width: '200px'}}>{usuario.nombreclub}</span>
          </div>
          <hr className="m-0" />
          <ul className="navbar-nav justify-content-end flex-grow-1 text-dark">
            <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
              <i className="pi pi-user me-1 ms-1"></i>
              <Link to="/admin/afiliados" className="nav-link">
                Afiliados
              </Link>
            </li>
            <hr className="m-0"/>
            <li className="nav-item d-flex align-items-center" onClick={() => setVisible(false)}>
              <i className="pi pi-user me-1 ms-1"></i>
              <Link to="/admin" className="nav-link">
                Administración
              </Link>
            </li>
            <hr className="m-0"/>
            <li className="nav-item d-flex align-items-center" onClick={() => cerrarSesion()}>
              <i className="pi pi-sign-out me-1 ms-1 text-danger"></i>
              <Link
                to="/"
                className="nav-link"
                style={{ color: "red" }}
              >
                Cerrar Sesión
              </Link>
            </li>
            <hr className="m-0"/>
          </ul>
          <div className="d-flex justify-content-center align-items-end" style={{height: '67vh'}}>
            <Link to='https://www.prowebsports.com.co/inicio' target="_blank" className="mt-3" style={{fontSize: '12px', textDecoration: 'none'}}>© 2023 - {anioAutor.getFullYear()} prowebsports</Link>
          </div>
        </Sidebar>
      </div>
      <Routes>
        <Route
          path="/"
          element={<Administracion/>}
        />
        <Route
          path="/afiliados"
          element={<Afiliados/>}
        />
      </Routes>
    </>
  );
};

export default Panel;
