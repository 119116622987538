import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { IoMdContacts, IoMdPerson } from 'react-icons/io'

const Conctatenos = () => {

  const anioAutor = new Date();

  return (
    <motion.div id='contactenos' className='text-start mt-1' style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.2), rgba(47, 157, 247, 0.3))'}}
    initial={{opacity: 0}}
    whileInView={{opacity: 1}}
    transition={{duration: 1, delay: 0.3}}
    >
      <h4 className='p-3 mb-0' style={{fontFamily: 'Anton SC'}}>PARA MAYOR INFORMACIÓN COMUNICATE CON NOSOTROS.</h4>
      <div className='d-flex flex-wrap justify-content-around'>
        <div className='m-3' style={{maxWidth: '250px'}}>
          <img className='w-100' src={require('../img/pwsmascota-izquierda.png')} alt="img logo swimming" style={{filter: "drop-shadow(-1px 1px 2px black)"}} />
        </div>
        <div className='m-3'>
          <h5><IoMdContacts className='fs-2 text-info'/>Equipo</h5>
          <div className='d-flex flex-column'>
            <p className='m-0' style={{fontFamily: 'Gothic A1'}}><IoMdPerson className='fs-5 text-secondary'/> Admon. Willman Barbosa Velasquez.</p>
            <Link to="https://wa.me/573137394026" target='_blank' style={{textDecoration: 'none'}} className='text-dark'>+57 313-739-4026</Link>
            <p className='m-0' style={{fontFamily: 'Gothic A1'}}><IoMdPerson className='fs-5 text-secondary'/>Ing. Emanuel Barbosa Labrada.</p>
            <Link to="https://wa.me/573245884041" target='_blank' style={{textDecoration: 'none'}} className='text-dark'>+57 324-588-4041</Link>
            <p className='m-0' style={{fontFamily: 'Gothic A1'}}><IoMdPerson className='fs-5 text-secondary'/>Ing. Ywrani Barbosa Labrada.</p>
            <Link to="https://wa.me/573209147444" target='_blank' style={{textDecoration: 'none'}} className='text-dark'>+57 320-914-7444</Link>
          </div>
          <p className='mt-2' style={{fontFamily: 'Gothic A1'}}><b>© 2023 - {anioAutor.getFullYear()} PROWEBSPORTS</b></p>
        </div>
      </div>
    </motion.div>
  );
}

export default Conctatenos;