import React, { useState } from "react";
import { login } from "../controllers/configuracionFirebase";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";

const Login = () => {

  const [contrasena, setContrasena] = useState('');

  /* la funcion onSubmit traera todos los datos que se han ingresado en el login,
  y asi mismo validara si las credenciales son correctas o no. */
  function onSubmit(e) {
    e.preventDefault();

    const correo = e.target.email.value;
    const password = contrasena;
    login(correo, password);
    setContrasena('');
    e.target.reset();
  }

  return (
    <div style={{ width: "300px" }}>
      <form
        className={"column g-3 was-validated form-prime"}
        onSubmit={onSubmit}
      >
        <div className="col-auto mb-2">
          <img
            className="logo-pantalla-media"
            style={{ width: "100%" }}
            src={require("../img/img_logo_swimmingT.png")}
            alt="logo"
          />
        </div>
        <div className="alert alert-dark show mb-1" role="alert">
          <h1>
            ¡BIENVENIDO!
          </h1>
          <p className="mb-0">
          A la plataforma profesional de <br /> PROWEBSPORTS.
          </p>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon">
              <i className="pi pi-at"></i>
          </span>
          <InputText type="email" name="email" placeholder="E-mail@example.com" className="p-2" required/>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon">
              <i className={contrasena.length >= 6 ? 'pi pi-unlock' : 'pi pi-lock'}></i>
          </span>
          <Password value={contrasena} onChange={(e) => setContrasena(e.target.value)} placeholder="Contraseña" toggleMask minLength={6} required
          className={`${contrasena !== '' && contrasena.length >= 6 ? '' : 'p-invalid'}`}
          feedback={false}/>
        </div>
        <div className="col-auto mb-2">
          <Button type="submit" icon='pi pi-key' label="ACCESO" className="btn btn-dark w-100 mb-1"></Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
