import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom"

const Pdfs = ({filePdf}) => {

  const navigate = useNavigate();

  useEffect(() => {
    if(!filePdf){
      return navigate("/");
    }
  }, [filePdf, navigate])

  return (
    <div className='w-100' style={{height: '100vh'}}>
      {filePdf ? (
        <embed src={require('../archivos/cotizacion.pdf')} width="100%" height="100%" type="application/pdf" />
      ):(
        <></>
      )}
    </div>
  );
}

export default Pdfs;