import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import es from 'date-fns/locale/es'
import { arreglarNombresCortos } from '../controllers/pdf';

const locales = {
  'es': es,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const Calendario = ({ cliente }) => {

  const [ eventos, setEventos ] = useState([]);

  // Función para personalizar los estilos de los eventos
  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.color, // Asignar color personalizado
      color: 'white',
    };
    return {
      style: style,
    };
  };

  useEffect(() =>{
    const auxClientes = [];
    let contadorColor = 1;
    cliente.forEach((clie) =>{
      const objCliente = {
        title: clie.titulo + ' ' + arreglarNombresCortos(clie.club),
        start: new Date(clie.fecha),
        end: new Date(clie.fechaF)
      }

      objCliente.start.setDate(objCliente.start.getDate() + 1);
      objCliente.end.setDate(objCliente.end.getDate() + 1);

      switch (contadorColor) {
        case 1:
          objCliente.color = 'rgb(255, 193, 7)';
          break;
        case 2:
          objCliente.color = 'rgb(23, 162, 184)';
          break;
        case 3:
          objCliente.color = 'rgb(220, 53, 69)';
          contadorColor = 0;
          break;
        default:
          break;
      }

      contadorColor++;
      auxClientes.push(objCliente);
    })
    setEventos(auxClientes);
  },[])

  return (
    <Calendar
      localizer={localizer}
      events={eventos}
      startAccessor="start"
      endAccessor="end"
      eventPropGetter={eventStyleGetter}
      style={{ height: 500 }} 
    />
  );
}

export default Calendario;