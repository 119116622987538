import React from 'react';
import videoFile from '../videos/loading1.mp4';

const Loading = () => {
  return (
    <div className='d-flex justify-content-center align-items-center m-auto' style={{height: '100%', maxWidth: '600px'}}>
      <video autoPlay loop muted className='w-100'>
        <source src={videoFile} type="video/mp4" />
        Tu navegador no admite la reproducción de videos.
      </video>
    </div>
  );
}

export default Loading;