import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { activarPlataforma, desactivarPlataforma, registrarCliente, registrarEvaluacion, registrarNumeracionFactura, torneoPagado, traerClientesAdmin, traerEvaluacion } from '../controllers/configuracionFirebase';
import { facturacion, facturacionFactura } from '../controllers/pdf';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Tag } from 'primereact/tag';
import { Chart } from 'primereact/chart';

const Administracion = () => {

  const [ expandedRows, setExpandedRows ] = useState(null);
  const [ expandedRowsTorneo, setExpandedRowsTorneo ] = useState(null);
  const [ globalFilter, setGlobalFilter ] = useState(null);
  const [ clientes, setClientes ] = useState([]);
  const [ totalEvaluacion, setTotalEvaluacion ] = useState(0);
  const [ totalEvaluaciones, setTotalEvaluaciones ] = useState([]);
  const [ pdfUrl, setPdfUrl ] = useState();
  const [ dialogTorneos, setDialogTorneos ] = useState(false);
  const [ selectedCliente, setSelectedCliente ] = useState(null);
  const [ chartEvaluaciones, setChartEvaluaciones ] = useState([]);
  const [ chartData, setChartData ] = useState({});
  const [ chartOptions, setChartOptions ] = useState({});
  const date = new Date();

  function sacarTotal(){
    let auxTotal = 0;
    clientes.forEach((clie)=>{
      auxTotal += sacarTotalCadaUno(clie.torneos, clie.pagar);
    })
    return auxTotal;
  }

  function sacarTotalCadaUno(torneos, pagar){
    let auxTotal = 0;
    torneos.forEach((tor)=>{
      auxTotal += (parseInt(tor.deportistas) * pagar)
    })
    return auxTotal;
  }

  const header = (
    <div className="d-flex justify-content-start align-items-center">
      <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
      </span>
    </div>
  );

  const agregarClientes = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button icon="pi pi-users" severity="success" raised tooltip='Registrar Cliente' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogTorneos(true)}/>
        <Button className='ms-1' icon="pi pi-dollar" severity="secondary" raised tooltip='Registrar Total' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => registrarEvaluacion(sacarTotal())}/>
      </div>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable value={[data]} selectionMode="single" expandedRows={expandedRowsTorneo} onRowToggle={(e) => setExpandedRowsTorneo(e.data)} rowExpansionTemplate={rowExpansionTemplateTorneos} dragSelection size='small' stripedRows>
        <Column expander style={{ width: '3em' }} />
        <Column field="tipo" header="Tipo" style={{ width: '110px'}}></Column>
        <Column header="Vencimiento" body={(clie) =>{return clie.vence && clie.vence !== '' ? clie.vence : 'N/A'}} style={{ width: '80px'}}></Column>
        <Column header="Forma Pago" body={(clie) =>{return clie.Fpago && clie.Fpago !== '' ? clie.Fpago : 'N/A'}} style={{ width: '80px'}}></Column>
        <Column header="Total" body={(clie) =>{return clie.totalAPagar ? '$' + clie.totalAPagar.toLocaleString() : 0}} style={{ width: '80px'}}></Column>
      </DataTable>
    );
  };

  const rowExpansionTemplateTorneos = (data) => {
    return (
      <DataTable value={data.torneos}>
        <Column field="titulo" header="Evento" style={{ width: '110px'}}></Column>
        <Column field="fecha" header="Fecha" style={{ width: '80px'}}></Column>
        <Column field="deportistas" header="No Deportistas" style={{ width: '80px'}}></Column>
      </DataTable>
    );
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={6} footerStyle={{ textAlign: 'right' }} />
        <Column footer={() => {return `$${sacarTotal().toLocaleString()}`}} />
      </Row>
    </ColumnGroup>
  );

  function onSubmit(e){
    e.preventDefault();

    const nombre = e.target.nombre.value;
    const club = e.target.nombreclub.value;
    const cel = e.target.cel.value;
    const correo = e.target.correo.value;
    const ubicacion = e.target.ubicacion.value;
    const tipo = e.target.tipo.value;
    const vencimiento = e.target.vencimiento.value;
    const pago = e.target.pago.value;
    
    const objCliente = {
      nombre,
      club,
      cel,
      correo,
      ubicacion,
      torneos: [],
      pf: false,
      tipo,
      vence: vencimiento,
      Fpago: pago,
      pagar: 0
    }
    
    registrarCliente(objCliente);

    e.target.reset();
  }

  function modificarCliente(){
    const objCliente = {
      club: selectedCliente.club,
      vence: document.getElementById('vencimientoF').value,
      Fpago: document.getElementById('pagoF').value,
      pagar: parseInt(document.getElementById('pagar').value)
    }

    registrarCliente(objCliente);
  }

  function pagado(indexClie, indexTor, numeracion){
    const auxTorneos = [];
    clientes[indexClie].torneos.forEach((torn, index)=>{
      if(index !== indexTor){
        auxTorneos.push(torn);
      }
    })
    torneoPagado(clientes[indexClie].club, auxTorneos);
    registrarNumeracionFactura(numeracion);
  }

  function totalEvaluacionesAnioActual(){
    const auxTotal = totalEvaluaciones.filter((tol)=>{return tol.anio === date.getFullYear()})[0] ? totalEvaluaciones.filter((tol)=>{return tol.anio === date.getFullYear()})[0] : 0;
    return totalEvaluacion + auxTotal.total;
  }

  function generarNumeroFactura(numeroFactura) {
    let numeroActual = parseInt(numeroFactura.split('-')[1]);

    // Incrementa el número de factura
    numeroFactura = numeroActual + 1;
  
    // Formatea el número con ceros a la izquierda para que tenga 4 dígitos
    let numeroFormateado = numeroFactura.toString().padStart(4, '0');
  
    // Construye la numeración completa con el prefijo
    let numeracionCompleta = `PWS-${numeroFormateado}`;
  
    return numeracionCompleta;
  }

  function numeracionFactura(){
    if(totalEvaluaciones.length !== 0){
      const auxTotal = totalEvaluaciones.filter((tol)=>{return tol.anio === date.getFullYear()})[0] ? totalEvaluaciones.filter((tol)=>{return tol.anio === date.getFullYear()})[0] : 0;
      const facturaActual = auxTotal === 0 ? 'PWS-0000' : auxTotal.factura;
      
      let numero = facturaActual;
      if(auxTotal !== 0){
        numero = generarNumeroFactura(facturaActual);
      }
      return numero;
    }
  }

  useEffect(() => {
    traerClientesAdmin(setClientes);
  },[setClientes])

  useEffect(()=>{
    traerEvaluacion(setTotalEvaluaciones);
  },[setTotalEvaluaciones])

  useEffect(() =>{
    const auxTotales = [];
    const auxAnios = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031];
    auxAnios.forEach((anios)=>{
      const buscarAnio = totalEvaluaciones.filter((total)=>{return total.anio === anios});
      if(buscarAnio[0]){
        if(buscarAnio[0].anio === date.getFullYear()){
          auxTotales.push(sacarTotal()); 
        }else{
          auxTotales.push(buscarAnio[0].total);
        }
      }else{
        auxTotales.push(0);
      }
    })

    setChartEvaluaciones(auxTotales);
  },[totalEvaluaciones])

  useEffect(() => {
    const data = {
      labels: ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
      datasets: [
        {
          label: 'Crecimiento PROWEBSPORTS',
          data: chartEvaluaciones,
          backgroundColor: [
            'rgba(255, 255, 0, 0.2)',  // Amarillo
            'rgba(0, 0, 255, 0.2)',    // Azul
            'rgba(255, 0, 0, 0.2)',    // Rojo
            'rgba(255, 255, 0, 0.2)',  // Amarillo
            'rgba(0, 0, 255, 0.2)',    // Azul
            'rgba(255, 0, 0, 0.2)',    // Rojo
            'rgba(255, 255, 0, 0.2)',  // Amarillo
            'rgba(0, 0, 255, 0.2)',    // Azul
            'rgba(255, 0, 0, 0.2)',    // Rojo
            'rgba(255, 255, 0, 0.2)'   // Amarillo
          ],
          borderColor: [
            'rgb(255, 255, 0)',  // Amarillo
            'rgb(0, 0, 255)',    // Azul
            'rgb(255, 0, 0)',    // Rojo
            'rgb(255, 255, 0)',  // Amarillo
            'rgb(0, 0, 255)',    // Azul
            'rgb(255, 0, 0)',    // Rojo
            'rgb(255, 255, 0)',  // Amarillo
            'rgb(0, 0, 255)',    // Azul
            'rgb(255, 0, 0)',    // Rojo
            'rgb(255, 255, 0)'   // Amarillo
          ],
          borderWidth: 1
        },
        {
          type: 'line',
          label: 'PWS',
          data: chartEvaluaciones,
          fill: false,
          borderDash: [5, 5],
          tension: 0.4,
          backgroundColor: [
            'rgba(255, 255, 0, 0.2)',  // Amarillo
            'rgba(0, 0, 255, 0.2)',    // Azul
            'rgba(255, 0, 0, 0.2)',    // Rojo
            'rgba(255, 255, 0, 0.2)',  // Amarillo
            'rgba(0, 0, 255, 0.2)',    // Azul
            'rgba(255, 0, 0, 0.2)',    // Rojo
            'rgba(255, 255, 0, 0.2)',  // Amarillo
            'rgba(0, 0, 255, 0.2)',    // Azul
            'rgba(255, 0, 0, 0.2)',    // Rojo
            'rgba(255, 255, 0, 0.2)'   // Amarillo
          ],
          borderColor: [
            'rgb(255, 255, 0)',  // Amarillo
            'rgb(0, 0, 255)',    // Azul
            'rgb(255, 0, 0)',    // Rojo
            'rgb(255, 255, 0)',  // Amarillo
            'rgb(0, 0, 255)',    // Azul
            'rgb(255, 0, 0)',    // Rojo
            'rgb(255, 255, 0)',  // Amarillo
            'rgb(0, 0, 255)',    // Azul
            'rgb(255, 0, 0)',    // Rojo
            'rgb(255, 255, 0)'   // Amarillo
          ],
        },
      ]
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    };

    setChartData(data);
    setChartOptions(options);
}, [chartEvaluaciones]);

  return (
    <>
      <Toolbar left={agregarClientes}></Toolbar>
      <DataTable value={clientes} selectionMode="single" selection={selectedCliente} onSelectionChange={(e) => setSelectedCliente(e.value)} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} header={header} globalFilter={globalFilter} dragSelection size='small' stripedRows paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100]} 
      footerColumnGroup={footerGroup} tableStyle={{ minWidth: '73rem', margin: 'auto'}}>
        <Column expander style={{ width: '3em' }} />
        <Column field="nombre" sortable header="Nombre" style={{ width: '180px', fontWeight: 'bold' }}></Column>
        <Column field="club" sortable header="Equipo" style={{ width: '100px'}}></Column>
        <Column field="cel" sortable header="No Celular" style={{ width: '100px'}}></Column>
        <Column field="correo" sortable header="E-mail" style={{ width: '180px'}}></Column>
        <Column sortable header="Estado" body={(clie) => clie.pf ? <Tag severity="success" value="Activo"></Tag> : <Tag severity="danger" value="Desactivo"></Tag>} style={{ width: '100px'}}></Column>
        <Column sortable header="Total" body={(clie) =>{return `$${sacarTotalCadaUno(clie.torneos, clie.pagar).toLocaleString()}`}} style={{ width: '100px'}}></Column>
      </DataTable>
      <div className="card">
        <Chart type="bar" style={{ width: '95vw', height: '100%', margin: 'auto' }}  data={chartData} options={chartOptions} />
      </div>
      <Dialog visible={dialogTorneos} style={{ width: '46rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={'Clientes'} modal className="p-fluid" onHide={() => setDialogTorneos(false)}>
        <form onSubmit={onSubmit}>
          <div className='d-flex flex-wrap justify-content-between'>
            <div className="form-floating mb-2" style={{width: '100px'}}>
              <select name="tipo" id='tipo' className='form-select' required>
                <option value="">Elige</option>
                <option value="torneos">Torneos</option>
                <option value="administrativas">Administrativa</option>
              </select>
              <label htmlFor="tipo">Tipo</label>
            </div>
            <div className="form-floating mb-2" style={{width: '300px'}}>
              <input type="text" className='form-control' id="nombre" name="nombre" required/>
              <label htmlFor="nombre">Nombre Completo</label>
            </div>
            <div className="form-floating mb-2" style={{width: '270px'}}>
              <input type="text" className='form-control' id="nombreclub" name="nombreclub" required/>
              <label htmlFor="nombreclub">Nombre Equipo</label>
            </div>
            <div className="form-floating mb-2" style={{width: '140px'}}>
              <input type="number" className='form-control' id="cel" name="cel" required/>
              <label htmlFor="cel">No. Celular</label>
            </div>
            <div className="form-floating mb-2" style={{width: '350px'}}>
              <input type="email" className='form-control' id="correo" name="correo" required/>
              <label htmlFor="correo">E-mail</label>
            </div>
            <div className="form-floating mb-2" style={{width: '180px'}}>
              <input type="text" className='form-control' id="ubicacion" name="ubicacion" required/>
              <label htmlFor="ubicacion">Ubicación</label>
            </div>
            <div className="form-floating mb-2" style={{width: '180px'}}>
              <input type="date" className='form-control' id="vencimiento" name="vencimiento"/>
              <label htmlFor="vencimiento">Vencimiento</label>
            </div>
            <div className="form-floating mb-2" style={{width: '150px'}}>
              <select name="pago" id='pago' className='form-select'>
                <option value="">Elige</option>
                <option value="1">1 Mes</option>
                <option value="6">6 Meses</option>
                <option value="12">12 Meses</option>
              </select>
              <label htmlFor="pago">Forma de pago</label>
            </div>
          </div>
          <input type="submit" value='Registrar' className='btn btn-success'/>
        </form>
      </Dialog>
      <Dialog visible={selectedCliente} style={{ width: '50em' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={`Factura ${numeracionFactura()}`} modal className="p-fluid" onHide={() => setSelectedCliente(null)}>
        <div className='field'>
          <div className='d-flex flex-wrap justify-content-between'>
            <div className="form-floating mb-2" style={{width: '100px'}}>
              <select name="tipoF" id='tipoF' className='form-select' required>
                <option value="">Elige</option>
                <option value="torneos">Torneos</option>
                <option value="administrativas">Administrativa</option>
              </select>
              <label htmlFor="tipoF">Tipo</label>
            </div>
            <div className="form-floating mb-2" style={{width: '300px'}}>
              <input type="text" className='form-control' id="nombreF" name="nombreF" defaultValue={selectedCliente ? selectedCliente.nombre : ''}/>
              <label htmlFor="nombreF">Nombre Completo</label>
            </div>
            <div className="form-floating mb-2" style={{width: '270px'}}>
              <input type="text" className='form-control' id="nombreclubF" name="nombreclubF" defaultValue={selectedCliente ? selectedCliente.club : ''}/>
              <label htmlFor="nombreclubF">Nombre Equipo</label>
            </div>
            <div className="form-floating mb-2" style={{width: '140px'}}>
              <input type="number" className='form-control' id="celF" name="celF" defaultValue={selectedCliente ? selectedCliente.cel : ''}/>
              <label htmlFor="celF">No. Celular</label>
            </div>
            <div className="form-floating mb-2" style={{width: '350px'}}>
              <input type="email" className='form-control' id="correoF" name="correoF" defaultValue={selectedCliente ? selectedCliente.correo : ''}/>
              <label htmlFor="correoF">E-mail</label>
            </div>
            <div className="form-floating mb-2" style={{width: '180px'}}>
              <input type="text" className='form-control' id="ubicacionF" name="ubicacionF" defaultValue={selectedCliente ? selectedCliente.ubicacion : ''}/>
              <label htmlFor="ubicacionF">Ubicación</label>
            </div>
            <div className="form-floating mb-2" style={{width: '180px'}}>
              <input type="date" className='form-control' id="vencimientoF" name="vencimientoF"/>
              <label htmlFor="vencimientoF">Vencimiento</label>
            </div>
            <div className="form-floating mb-2" style={{width: '150px'}}>
              <select name="eventoF" id='eventoF' className='form-select'>
                <option value="">Elige</option>
                {selectedCliente?.torneos.map((tor, indexTor)=>{
                  return <option value={indexTor}>{tor.titulo}</option>
                })}
              </select>
              <label htmlFor="eventoF">Eventos</label>
            </div>
            <div className="form-floating mb-2" style={{width: '150px'}}>
              <select name="pagoF" id='pagoF' className='form-select'>
                <option value="">Elige</option>
                <option value="1">1 Mes</option>
                <option value="6">6 Meses</option>
                <option value="12">12 Meses</option>
              </select>
              <label htmlFor="pagoF">Forma de pago</label>
            </div>
            <div className="form-floating mb-2" style={{width: '150px'}}>
              <select name="descuentoF" id='descuentoF' className='form-select'>
                <option value="0">0%</option>
                <option value="10">10%</option>
                <option value="20">20%</option>
                <option value="30">30%</option>
                <option value="40">40%</option>
                <option value="42.857">42,857%</option>
                <option value="50">50%</option>
                <option value="60">60%</option>
                <option value="70">70%</option>
                <option value="80">80%</option>
                <option value="90">90%</option>
                <option value="100">100%</option>
              </select>
              <label htmlFor="descuentoF">Descuento</label>
            </div>
            <div className="form-floating mb-2" style={{width: '150px'}}>
              <select name="motivoF" id='motivoF' className='form-select'>
                <option value="Ninguno">Ninguno</option>
                <option value="Prueba">Prueba</option>
                <option value="Recomendación">Recomendación</option>
                <option value="Patrocinio">Patrocinio</option>
                <option value="Primer Torneo">Primer Torneo</option>
              </select>
              <label htmlFor="motivoF">Descuento</label>
            </div>
            <div className="form-floating mb-2" style={{width: '150px'}}>
              <select name="estadoF" id='estadoF' className='form-select'>
                <option value="Pendiente">Pendiente</option>
                <option value="Pagado">Pagado</option>
              </select>
              <label htmlFor="estadoF">Estado Factura</label>
            </div>
            <div className="form-floating mb-2" style={{width: '180px'}}>
              <input type="number" className='form-control' id="pagar" name="pagar" defaultValue={selectedCliente ? selectedCliente.pagar : 0}/>
              <label htmlFor="pagar">Valor A Pagar</label>
            </div>
          </div>
          <hr />
          <div className='d-flex flex-wrap justify-content-center'>
            <Button className='btn btn-danger' icon='pi pi-file-pdf' label='Generar Factura'
            onClick={() => document.getElementById('tipoF').value === 'torneos' ? facturacion(selectedCliente, selectedCliente.torneos[parseInt(document.getElementById('eventoF').value)], document.getElementById('estadoF').value, setPdfUrl, document.getElementById('descuentoF').value, document.getElementById('motivoF').value, numeracionFactura()) : facturacionFactura(selectedCliente, setPdfUrl, numeracionFactura())} style={{width: '200px'}}></Button>
            <Button className='btn btn-secondary ms-1 me-1' icon='pi pi-pencil' label='Modificar' style={{width: '200px'}} onClick={() => modificarCliente()}></Button>
            <Button className={selectedCliente?.pf ? 'btn btn-danger' : 'btn btn-success'} icon={selectedCliente?.pf ? 'pi pi-times-circle' : 'pi pi-check-circle'} label={selectedCliente?.pf ? 'Desactivar' : 'Activar'} 
            onClick={() => selectedCliente?.pf ? desactivarPlataforma(selectedCliente.club) : activarPlataforma(selectedCliente.club)} style={{width: '200px'}}></Button>
          </div>
        </div>
      </Dialog>
      <Dialog visible={pdfUrl ? true : false} style={{ width: '50em' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Factura Electrónica" modal className="p-fluid" onHide={() => setPdfUrl()}>
        <div className="field">
          <div className='mb-2' style={{width: '100%', height: '555px'}}>
            <embed src={pdfUrl} width='100%' height='100%' type='application/pdf'/>
          </div>
          <div className='d-flex justify-content-between mb-2'>
            <Button label='Confirmar Factura' icon="pi pi-check" severity='success' className='p-2' style={{borderRadius: '10px'}} onClick={() => registrarNumeracionFactura(numeracionFactura())}></Button>
            <Button icon="pi pi-download" className='p-2' text tooltip='Descargar Factura' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '10px'}}></Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default Administracion;