// coockie reacciones
export function setReaccionCookie(nombre, valor) {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  document.cookie = nombre + "=" + valor + ";path=/;expires=" + date.toUTCString() + ";SameSite=Lax";
}

export function getCookieReaccion(nombre) {
  let cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(nombre + "=") === 0) {
      return cookie.substring(nombre.length + 1, cookie.length);
    }
  }
  return "";
}