import React from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import Ranking from './Ranking';
import TablaRecords from './TablaRecords';

const Records = ({ recordsMundial, recordsNacional }) =>{
  return(
    <>
      <TabView scrollable>
        <TabPanel header="Ranking PWS" leftIcon='pi pi-users me-2'>
          <Ranking/>
        </TabPanel>
        <TabPanel header="Records Mundial" leftIcon='pi pi-globe me-2'>
          <TablaRecords titulo={'MUNDIAL'} records={recordsMundial}/>
        </TabPanel>
        <TabPanel header="Records Nacional" leftIcon='pi pi-globe me-2'>
          <TablaRecords titulo={'NACIONAL'} records={recordsNacional}/>
        </TabPanel>
      </TabView>
    </>
  )
}

export default Records;