import axios from "axios";

export const fetchData = async (setFechaGoogle) => {
  try {
    const response = await axios.get('https://worldtimeapi.org/api/ip');
    // Manejar la respuesta
    const currentDate = new Date(response.data.datetime);
    setFechaGoogle(currentDate);
  } catch (error) {
    const auxFechaDispositivo = new Date();
    setFechaGoogle(auxFechaDispositivo);
  }
};

function arreglarFechaInscripciones(fecha){
  const auxFecha = new Date(fecha);
  const fechaArreglada = auxFecha.toLocaleDateString('en-US',{ 
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true})
  return fechaArreglada;
}

export async function time(clie, index, fecha){
  //await fetchData()
  const date = fecha;
  const festivalDias = document.getElementById(`dia${index}`);
  const dia_hora = document.getElementById(`dia-hora${index}`);
  const contenedorFecha = document.getElementById(`contenedor-fecha${index}`);
  const inscripcionesCerradas = document.getElementById(`inscripcionesCerradas${index}`);
  const marquee = document.getElementById(`marquee${index}`);
  const marqueeSpan = document.getElementById(`marqueeSpan${index}`);
  marquee.classList.add("marquee");
  
  if (date) {
    //fecha a futuro
    const fechaFuturo = new Date(clie.fechaInscripciones);
    //Obtener fecha actual y milisegundos
    const fechaActual = new Date(date);
    //Obtener la distancia entre ambas fechas
    let distancia = fechaFuturo.getTime() - fechaActual.getTime() 
    
    //Calculos dias, horas, minutos, segundos
    let dias = Math.floor(distancia / (1000 * 60 * 60 * 24))
    let horas = Math.floor((distancia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))

    if(festivalDias){
      //Escribimos resultados
      if(dias > 0){
        festivalDias.innerHTML = ('0' + dias).slice(dias >= 100 ? -3 : -2);
        dia_hora.innerHTML = (' Dias');
      }else{
        if(horas > 0){
          festivalDias.innerHTML = ('0' + horas).slice(-2);
          dia_hora.innerHTML = (' Horas');
        }
        if(horas === 0){
          festivalDias.style.display = 'none';
          dia_hora.innerHTML = (' Minutos');
        }
      }
      if(dias >= 5){
        marqueeSpan.innerHTML = 'Aún estás a tiempo, ¡INSCRÍBETE YA!';
      }else if(dias > 1 && dias < 5){
        marqueeSpan.innerHTML = '¡Últimos días para inscribirte! No te quedes fuera, el tiempo se agota.';
      }else if(dias === 1){
        marqueeSpan.innerHTML = '¡Mañana cierran las inscripciones! Asegúrate de inscribirte antes de que sea demasiado tarde.';
      }else if(dias < 1){
        marqueeSpan.innerHTML = '¡Apúrate! Las inscripciones están a punto de cerrarse.';
      }
      if (distancia < 0) {
        contenedorFecha.style.display = 'none';
        if(clie.estado === 'inscripciones'){
          inscripcionesCerradas.innerHTML = ('Inscripciones Cerradas');
          marqueeSpan.innerHTML = 'Las inscripciones han cerrado. Gracias por tu interés y participación.';
        }
        
        if(clie.estado === 'evento'){
          inscripcionesCerradas.innerHTML = ('Evento En Curso...');
          marqueeSpan.innerHTML = 'El evento está en curso. ¡Sigue los resultados en tiempo real aquí!';
        }
        
        if(clie.estado === 'terminado'){
          inscripcionesCerradas.innerHTML = ('Evento Terminado');
          marqueeSpan.innerHTML = 'El evento ha finalizado. ¡Gracias por participar!';
        }
      }else{
        inscripcionesCerradas.innerHTML = arreglarFechaInscripciones(clie.fechaInscripciones) + '&nbsp;';
      }
    }
  }
}

export function timeLocal(fechaCierre, index){
  function festival() {
    const festivalDias = document.getElementById(`diaLocal${index}`);
    const festivalHoras = document.getElementById(`horaLocal${index}`);
    const festivalMinutos = document.getElementById(`minutoLocal${index}`);
    const festivalSegundos = document.getElementById(`segundoLocal${index}`);
    const contenedorFecha = document.getElementById(`contenedor-diaLocal${index}`);
    //fecha a futuro
    const fechaFuturo = new Date(fechaCierre).getTime()
    //Obtener fecha actual y milisegundos
    const fechaActual = new Date().getTime()
    //Obtener la distancia entre ambas fechas
    let distancia = fechaFuturo - fechaActual
    //Calculos dias, horas, minutos, segundos
    let dias = Math.floor(distancia / (1000 * 60 * 60 * 24))
    let horas = Math.floor((distancia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let minutos = Math.floor((distancia % (1000 * 60 * 60)) / (1000 * 60))
    let segundos = Math.floor((distancia % (1000 * 60)) / (1000))
    
    if(festivalDias && festivalHoras && festivalMinutos && festivalSegundos){
      //Escribimos resultados
      festivalDias.innerHTML = ('0' + dias).slice(dias >= 100 ? -3 : -2)
      festivalHoras.innerHTML = ('0' + horas).slice(-2)
      festivalMinutos.innerHTML = ('0' + minutos).slice(-2)
      festivalSegundos.innerHTML = ('0' + segundos).slice(-2)
      
      if (distancia < 0) {
        clearInterval(interval);
        contenedorFecha.style.display = 'none';
      }
    }
  }
      
  let interval = setInterval(function () {
    festival()
  }, 1000)
}

export function reloj() {
  function tiempoActual(){
    const relojSpan = document.getElementById(`reloj`);
    const ahora = new Date();
    let horas = ahora.getHours();
    const minutos = ahora.getMinutes();
    const segundos = ahora.getSeconds();
    const ampm = horas >= 12 ? 'PM' : 'AM';

    // Convertir a formato de 12 horas
    horas = horas % 12 || 12;

    const tiempoFormateado = `${horas < 10 ? '0' : ''}${horas}:${minutos < 10 ? '0' : ''}${minutos}:${segundos < 10 ? '0' : ''}${segundos} ${ampm}`;
    if(relojSpan){
      relojSpan.innerHTML = tiempoFormateado;
    }
  }
  setInterval(function () {
    tiempoActual();
  }, 1000)
}

export function anioAutor(){
  const ahora = new Date();
  return ahora.getFullYear();
}