import React from 'react';
import { motion } from 'framer-motion';
import Conctatenos from './Conctatenos';
import ReactPlayer from "react-player";

const Caracteristicas = () => {
  return (
    <motion.div id='caracteristicas' className='text-center' style={{height: '100%'}}
    initial={{opacity: 0}}
    animate={{opacity: 1}}
    transition={{duration: 1}}
    >
        <ReactPlayer
          url="https://www.youtube.com/watch?v=a-DClyo_QKM&t=4s"
          width="100"
          height="40vw"
          controls
          loop
          playing
        />
      <div className='w-100 ms-0 row row-cols-1 row-cols-sm-2 row-cols-md-4 justify-content-center'>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Registro de clubes
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión/Invitados/Usuarios</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-registrarse.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Crear tus propios Torneos
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-crearTorneos.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Configura las Jdas, Pbas y Cgas
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-configTorneo.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Autorizaciones
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-autorizaciones.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Listado de torneos y participar
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión/Invitados</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-listTorneos.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Inscripción de nadadores
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión/Invitados</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-inscripcion.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Deportistas inscritos
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión/Invitados</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-deportistas.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Inscripción de relevos
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-relevos.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Clubs inscritos
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-clubsInscritos.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Clubs inscritos .PDF
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-reporteClubs.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Programación Digital
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-progDigital.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Programación .pdf
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-progPdf.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Ingreso de tiempos
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-ingTiempos.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Resultados en tiempo real
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión/Invitados</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-resultTiempos.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Resultados en .PDF c/a Prueba
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión/Invitados</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-resultPdf.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Resultados para pantallas grandes
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión/Invitados</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-pantallaGrande.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Resultados en tiempo real familias
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión/Invitados/Usuarios</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-resultFamilia.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Puestos trofeo por medallas
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión/Invitados</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-trofeo.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Puestos trofeo por puntos
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión/Invitados</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-trofeoPuntos.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Estadísticas de categorías
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión/Invitados</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-estadisticas.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
        <motion.div className="col card m-1 shadow p-0"
        initial={{scale: 0, scaleX: -1}}
        whileInView={{scale: 1, scaleX: 1}}
        transition={{duration: 0.5, delay: 0.3}}
        style={{width: "25rem"}}
        >
          <div className="card-header d-flex justify-content-center flex-column" style={{fontFamily: 'IBM Plex Mono', background: 'linear-gradient(80deg, rgb(47, 157, 247), white)'}}>
            Ranking PROWEBSPORTS
            <div>
              <i className='pi pi-eye'></i>
              <span>Anfitrión/Invitados</span>
            </div>
          </div>
          <div className="card-body p-0">
            <img className='w-100' src={require('../img/img-caracteristicas-rankingPWS.png')} alt="imagen de prowebsports" />
          </div>
        </motion.div>
      </div>
      <Conctatenos/>
    </motion.div>
  );
}

export default Caracteristicas;