// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
  getAuth, 
  onAuthStateChanged, 
  signOut,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode
} from "firebase/auth";
import { getFirestore,  collection, onSnapshot, doc, getDoc, setDoc, updateDoc, getDocs, deleteDoc } from "firebase/firestore";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//Base de datos de prowebsports inscripciones, tiempos, planillas, etc...
const firebaseConfig = {
  apiKey: "AIzaSyB2JfPd9BCJXSBkT5ihk04dvAdrRUFygbc",
  authDomain: "prowebsports.firebaseapp.com",
  projectId: "prowebsports",
  storageBucket: "prowebsports.appspot.com",
  messagingSenderId: "526046283180",
  appId: "1:526046283180:web:1c24920d5faf4b0d0ce609",
  measurementId: "G-XDNEZY0ZEN"
};

const firebaseConfigFamilia = {
  apiKey: "AIzaSyD1PKupANYM3CX1JO-CMsBX63QMaNLF11Q",
  authDomain: "pws-familia.firebaseapp.com",
  projectId: "pws-familia",
  storageBucket: "pws-familia.appspot.com",
  messagingSenderId: "121430827570",
  appId: "1:121430827570:web:857221a585028b02e1522b",
  measurementId: "G-XN337H7J99"
};

// Initialize Firebase 
const db = initializeApp(firebaseConfig);
const auth = getAuth(db);
const firestore = getFirestore(db);

//Initialize Firebase resultados para familiares y demas Prowebsports
const dbFamilia = initializeApp(firebaseConfigFamilia, 'db2');
const firestoreFamilia = getFirestore(dbFamilia);

//Con esta funcion vamos a saber los cambios en la sesión.
export function sesionIniciada(setUsuario){
  onAuthStateChanged(auth, (usuarioFirebase) => {
    let datosUsuario = {};
    if(usuarioFirebase){
      datosUsuario = {
        nombreclub: auth.currentUser.displayName,
        id: usuarioFirebase ? usuarioFirebase.uid : "",
        photo: auth.currentUser.photoURL,
        email: auth.currentUser.email,
        presidente: '',
        tel: ''
      }

      if(datosUsuario.nombreclub === 'PROWEBSPORTS'){
        setUsuario(datosUsuario);
      }else{
        cerrarSesion();
      }
    }else{
      setUsuario();
    }
  });
}

//Funcion para ingresar a la plataforma.
export async function login(email, password){
  signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      window.location.reload();
    })
    .catch((err) => {
      if(err.code === 'auth/wrong-password'){
        toast.error("Contraseña incorrecta. Por favor, intentalo de nuevo.");
      }
      if(err.code === 'auth/user-not-found'){
        toast.error("¡UPS! el correo " + email + ", no esta registrado aún.");
      }
      if(err.code === 'auth/user-disabled'){
        toast.error("Lo sentimos, pero esta cuenta ha sido inhabilitada, comunicate con el equipo de PROWEBSPORTS.");
      }
      if(err.code === 'auth/network-request-failed'){
        toast.error('Sin conexion a internet');
      }
    });
}

//Funcion para cerrar sesión.
export function cerrarSesion(){
  signOut(auth);
}

// configuracion de traer datos de los torneos
//Esta funcion nos ayudara a traer los datos del club.
export async function traerClientes(setCliente) {
  const docRef = collection(firestore, `clientes`);
  onSnapshot(docRef, (snapshot) => {
    const auxClientes = [];
    const fechaActualISO = new Date().toISOString().split('T')[0];

    snapshot.forEach((cliente) => {
      if (cliente.data().tipo === 'torneos' && cliente.data().torneos.length !== 0) {
        cliente.data().torneos.forEach((torn)=>{
          if(torn.publicidad === 'si'){
            torn.club = cliente.data().club;
            auxClientes.push(torn);
          }
        })
      }
    });
    auxClientes.forEach((a, b) => {
      const fechaA = new Date(a.fechaF);
      const fechaB = new Date(b.fechaF);
      const diferenciaA = Math.abs(fechaA - new Date(fechaActualISO)) * (fechaA < new Date(fechaActualISO) ? 1 : -1);
      const diferenciaB = Math.abs(fechaB - new Date(fechaActualISO)) * (fechaB < new Date(fechaActualISO) ? 1 : -1);
      return diferenciaA - diferenciaB;
    });
    // Recorrer el array de clientes y agregar el atributo "vencimiento"
    auxClientes.forEach(tor => {
      const fechaActual = new Date(tor.fechaF);
      // Ajustar fechaActual para que sea a las 00:00:00
      fechaActual.setDate(fechaActual.getDate() + 1);
      fechaActual.setHours(0, 0, 0, 0);

      const fechaTorneo = new Date();
      // Ajustar fechaTorneo para que sea a las 00:00:00
      fechaTorneo.setHours(0, 0, 0, 0);

      if (fechaTorneo <= fechaActual) {
        tor.vencimiento = false; // Agregar el atributo vencimiento con valor true
      } else {
        tor.vencimiento = true; // Agregar el atributo vencimiento con valor false
      }

      if (fechaTorneo < fechaActual) {
        tor.estado = 'inscripciones';
      } else if(fechaTorneo > fechaActual) {
        tor.estado = 'terminado';
      } else{
        tor.estado = 'evento';
      }
    });

    auxClientes.sort((a, b) => {
      const fechaA = new Date(a?.fechaF || fechaActualISO);
      const fechaB = new Date(b?.fechaF || fechaActualISO);

      // Verifica si la fecha ya ha pasado y ajusta el orden en consecuencia
      if (fechaA < new Date(fechaActualISO)) {
        return 1; // Mueve al final de la lista
      } else if (fechaB < new Date(fechaActualISO)) {
        return -1; // Mantiene en su posición actual
      } else {
        return Math.abs(fechaA - new Date(fechaActualISO)) - Math.abs(fechaB - new Date(fechaActualISO));
      }
    });

    setCliente(auxClientes);
  });
}
// fin configuracion de traer datos de los torneos

//Esta funcion nos ayudara a modificar los datos del club.
export async function registrarCliente(cliente){
  const docRef = doc(firestore, `clientes/${cliente.club}`);
  const docSnap = await getDoc(docRef);
  if(!docSnap.exists()){
    setDoc(docRef, cliente
    ).then(() =>{
      toast.success('Cliente registrado correctamente.');
    });
  }else{
    updateDoc(docRef, {
      vence: cliente.vence,
      Fpago: cliente.Fpago,
      pagar: cliente.pagar
    }).then(() =>{
      toast.success('Cliente Actualizado correctamente.');
    });
  }
}

//Esta funcion nos ayudara a traer los datos del club.
export async function traerClientesAdmin(setCliente){
  const docRef = collection(firestore, `clientes`);
  onSnapshot(docRef, (snapshot)=>{
    const auxClientes = [];
    snapshot.forEach((cliente)=>{
      auxClientes.push(cliente.data())
    })

    auxClientes.forEach((afi)=>{
      if(afi.vence && afi.vence !== ''){
        const fechaActual = new Date().getTime();
        const fechaPago = new Date(afi.vence).getTime();
        const distancia = fechaPago - fechaActual;
  
        // Convertir la diferencia de milisegundos a días
        const distanciaEnDias = distancia / (1000 * 60 * 60 * 24);
  
        // Calcular el número aproximado de meses
        const mesesFaltantes = Math.floor(distanciaEnDias / 30);
        if(mesesFaltantes <= 0){
          afi.totalAPagar = Math.abs(mesesFaltantes * 25000);
        }else{
          afi.totalAPagar = 0;
        }
      }
    })


    setCliente(auxClientes);
  })
}

//Esta funcion nos ayudara a modificar los datos de la evaluación.
export async function registrarEvaluacion(total){
  const date = new Date();
  const anio = date.getFullYear();
  const docRef = doc(firestore, `anio/${anio + ''}`);
  const docSnap = await getDoc(docRef);
  if(!docSnap.exists()){
    setDoc(docRef, {total: total + ''}
    ).then(() =>{
      toast.success('Evaluación registrada correctamente.');
    });
  }else{
    updateDoc(docRef, {total: total + ''}
    ).then(() =>{
      toast.success('Evaluación actualizada correctamente.');
    });
  }
}

//Esta funcion nos ayudara a modificar los datos de la numeracion factura.
export async function registrarNumeracionFactura(numeracion){
  const date = new Date();
  const anio = date.getFullYear();
  const docRef = doc(firestore, `anio/${anio + ''}`);
  const docSnap = await getDoc(docRef);
  if(!docSnap.exists()){
    setDoc(docRef, {factura: numeracion}
    ).then(() =>{
      toast.success('Numeración registrada correctamente.');
    });
  }else{
    updateDoc(docRef, {factura: numeracion}
    ).then(() =>{
      toast.success('Numeración actualizada correctamente.');
    });
  }
}

//Esta funcion nos ayudara a traer los datos de la evaluación.
export async function traerEvaluacion(setTotalEvaluaciones){
  const docRef = collection(firestore, `anio`);
  onSnapshot(docRef, (snapshot)=>{
    const auxAnios = [];
    snapshot.forEach((anios)=>{
      const aux = {
        anio: parseInt(anios.id),
        factura: anios.data().factura ? anios.data().factura : 0,
        total: parseInt(anios.data().total ? anios.data().total : 0)
      }
      auxAnios.push(aux);
    })

    setTotalEvaluaciones(auxAnios);
  })
}

export function torneoPagado(club, torneos){
  const docRef = doc(firestore, `clientes/${club}`);
  updateDoc(docRef, {torneos});
}

export function activarPlataforma(club){
  const docRef = doc(firestore, `clientes/${club}`);
  updateDoc(docRef, {pf: true});
}

export function desactivarPlataforma(club){
  const docRef = doc(firestore, `clientes/${club}`);
  updateDoc(docRef, {pf: false});
}

//---------------------- configuracion reacciones ----------------------
export async function registrarReaccion(reacciones){
  const docRef = doc(firestoreFamilia, `reaccion/reacciones`);
  const docSnap = await getDoc(docRef)
  if(docSnap.exists()){   
    updateDoc(docRef, reacciones);
  }else{
    setDoc(docRef, reacciones);
  }
}

export async function traerReacciones(setReacciones){
  const tiemposCollectionRef = collection(firestoreFamilia, `reaccion`);
  
  onSnapshot(tiemposCollectionRef, (querySnapshot) => {
    const resultados = querySnapshot.docs
      .filter((doc) => doc.exists)
      .map((doc) => doc.data())

      setReacciones(...resultados);
  });
}
//---------------------- fin configuracion reacciones ----------------------
//---------------------- configuracion afiliados ----------------------
//esta funcion nos ayudara a registrar todos los nadadores y asi tener un registro general de ellos.
export async function registrarDeportistaGeneral(id, deport){
  const docRef = doc(firestore, `ranking/${id}`);
  const docSnap = await getDoc(docRef)
  if(!docSnap.exists()){
    setDoc(docRef, deport);
    toast.success('Deportista registrado correctamente.');
  }else{
    updateDoc(docRef, {edad: deport.edad, federacion: deport.federacion});
    toast.success('Deportista Federado correctamente.');
  }
}

//esta funcion nos ayudara a eliminar el nadador federado.
export async function eliminarDeportistaGeneral(id){
  const docRef = doc(firestore, `ranking/${id}`);
  const docSnap = await getDoc(docRef)
  if(docSnap.exists()){
    deleteDoc(docRef);
    toast.success('Deportista Eliminado correctamente.');
  }
}

//esta funcion nos ayudara a reiniciar el ranking.
export async function reiniciarRankingGeneral(){
  const docRef = collection(firestore, `ranking`);
  const docSnap = await getDocs(docRef);
  const auxRanking = [];
  docSnap.forEach((ran)=>{
    auxRanking.push(ran.data());
  })
  if(auxRanking.length !== 0){
    auxRanking.forEach((auxRan)=>{
      const docRef2 = doc(firestore, `ranking/${auxRan.identificacion}`);
      updateDoc(docRef2, {pruebas: [], federacion: false, puntos: '0'});
    })
    toast.success('El Ranking se ha reiniciado correctamente.');
  }
}

function rangoEdades(edad, auxNadadores, nad, edadI, edadFin){
  if(edad >= edadI && edad <= edadFin){
    if (!auxNadadores[`${edadI} - ${edadFin}`]) {
      auxNadadores[`${edadI} - ${edadFin}`] = [];
    }
    auxNadadores[`${edadI} - ${edadFin}`].push(nad);
  }
}

const parseEdad = (edad) => {
  if (edad.includes('-')) {
    // Si la edad tiene un rango (ej: '20-80'), tomamos el valor menor
    return parseInt(edad.split('-')[1], 10);
  }
  if (edad.includes('y Mas')) {
    // Si la edad tiene un rango (ej: '20-80'), tomamos el valor menor
    return parseInt(edad.split('-')[0], 10);
  }
  // Si es un número único, lo convertimos a entero
  return parseInt(edad, 10);
};

//esta funcion nos ayudara a traer los ranking.
export async function traerRanking(setRanking, setRankingIndividual, setEdadesRanking){
  const docRef = collection(firestore, `ranking`);
  onSnapshot(docRef, (querySnapshot) => {
    const auxRanking = querySnapshot.docs
        .filter((doc) => doc.exists)
        .map((doc) => doc.data());

    setRankingIndividual(auxRanking);
    const auxNadadores = {};
    auxRanking.forEach((nad)=>{
      const edad = parseInt(nad.edad);
      if(edad >= 5 && edad <= 18){
        if (!auxNadadores[edad]) {
            auxNadadores[edad] = [];
        }
        auxNadadores[edad].push(nad);
      }
      rangoEdades(edad, auxNadadores, nad, 19, 24);
      rangoEdades(edad, auxNadadores, nad, 25, 29);
      rangoEdades(edad, auxNadadores, nad, 30, 34);
      rangoEdades(edad, auxNadadores, nad, 35, 39);
      rangoEdades(edad, auxNadadores, nad, 40, 44);
      rangoEdades(edad, auxNadadores, nad, 45, 49);
      rangoEdades(edad, auxNadadores, nad, 50, 54);
      rangoEdades(edad, auxNadadores, nad, 55, 59);
      rangoEdades(edad, auxNadadores, nad, 60, 64);
      rangoEdades(edad, auxNadadores, nad, 65, 69);
      rangoEdades(edad, auxNadadores, nad, 70, 74);
      rangoEdades(edad, auxNadadores, nad, 75, 79);
      if(edad >= 80){
        if (!auxNadadores['80 y Mas']) {
            auxNadadores['80 y Mas'] = [];
        }
        auxNadadores['80 y Mas'].push(nad);
      }
    })

    const auxEdadesRanking = [];
    Object.keys(auxNadadores).forEach((value, index)=>{
      const objEdadesRanking = {
        edad: value
      }
      auxEdadesRanking.push(objEdadesRanking);
    })
    auxEdadesRanking.sort((a,b)=> parseEdad(a.edad) - parseEdad(b.edad));
    setEdadesRanking(auxEdadesRanking);
    setRanking(auxNadadores);
  })
}

export async function traerDeportistaParaFederar(id, setDeportistaEncontrado, calcularEdad){
  const docRef = doc(firestore, `ranking/${id}`);
  const docSnap = await getDoc(docRef)
  if(docSnap.exists()){
    docSnap.data().edad = calcularEdad(docSnap.data().fechaNacimiento);
    if(!docSnap.data().federacion){
      toast.success('Este deportista se encuentra NO Federado actualmente.');
    }
    setDeportistaEncontrado(docSnap.data());
  }else{
    toast.error('El deportista que intenta buscar, no se encuentra en la lista de federados.');
  }
}
//---------------------- fin configuracion afiliados ----------------------
//---------------------- configuracion resultados familia ----------------------
//operacion para traer los resultados en tiempo real.
let unsubscribeTiemposFamilia = null;

export async function traerResultadosFamilia(idTorneo, setResultados, conexion){
  const tiemposCollectionRef = collection(firestoreFamilia, `${conexion}/${idTorneo}/tiempos`);
  
  unsubscribeTiemposFamilia = onSnapshot(tiemposCollectionRef, (querySnapshot) => {
    const resultados = querySnapshot.docs
      .filter((doc) => doc.exists)
      .map((doc) => doc.data())
      .sort((a, b) => a.id - b.id);

      setResultados(resultados);
  });
}

export function cancelarTiemposResultadosFamilia(){
  if (unsubscribeTiemposFamilia) {
    unsubscribeTiemposFamilia();
    unsubscribeTiemposFamilia = null;
  }
}
//---------------------- fin configuracion resultados familia ----------------------
// ------------------ configuracion para verificacion correo ----------------------
export async function aplicarVerificacionCorreo(code) {
  applyActionCode(auth, code).then(()=>{
    const alerta = Swal.mixin({
      toast: true,
      timer: '15000',
      timerProgressBar: true,
      showConfirmButton: false
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 50px; position: absolute; top: 6px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0">Tu correo se ha verificado con éxito. Ahora puedes iniciar sesión.
      </div>`,
      position: 'top-end',
      padding: 2
    })
  })
}
// ------------------ fin configuracion para verificacion correo ----------------------
// ------------------ configuracion para restablecer la contraseña ----------------------
export async function verificarCode(code){
  const verificacion = verifyPasswordResetCode(auth, code);
  return verificacion;
}

export async function cambiarPassword(code, nuevoPassword) {
  confirmPasswordReset(auth, code, nuevoPassword).then(() =>{
    toast.success('Se ha restablecido la contraseña correctamente.');
  }).catch((error) =>{
    switch (error.code) {
      case 'auth/expired-action-code':
        toast.error("El enlace de restablecimiento de contraseña ha expirado. Por favor, solicita un nuevo enlace.");
        break;
      case 'auth/invalid-action-code':
        toast.error("El enlace de restablecimiento de contraseña no es válido o ya ha sido utilizado. Solicita un nuevo enlace.");
        break;
      case 'auth/weak-password':
        toast.error("La nueva contraseña es demasiado débil. Asegúrate de que tenga al menos 6 caracteres.");
        break;
      case 'auth/user-disabled':
        toast.error("Esta cuenta ha sido deshabilitada. Contacta al soporte para obtener ayuda.");
        break;
      case 'auth/user-not-found':
        toast.error("No se encontró una cuenta asociada a este enlace. Verifica tu correo electrónico o intenta nuevamente.");
        break;
      default:
        toast.error("Ocurrió un error inesperado. Por favor, intenta nuevamente.");
    }
  });
}
// ------------------ fin configuracion para restablecer la contraseña ----------------------
// ------------------ configuracion para records Mundiales y Nacionales ----------------------
export async function registrarRecords(records, modo){
  const docRef = doc(firestore, `records/${modo}`);
  updateDoc(docRef, records);
  toast.success('Record actualizado correctamente.');
}

export async function traerRecords(setRecordsMundial, setRecordsNacional){
  const mundial = doc(firestore, `records/mundial`);
  const nacional = doc(firestore, `records/colombia`);
  const docMundial = await getDoc(mundial);
  const docNacional = await getDoc(nacional);
  if(docMundial.exists()){
    setRecordsMundial(docMundial.data());
  }
  if(docNacional.exists()){
    setRecordsNacional(docNacional.data());
  }
}
// ------------------ fin configuracion para records Mundiales y Nacionales ----------------------
// ------------------ configuracion para limpiar el ranking ----------------------

//async function limpiarRanking(){
//  const docRef = collection(firestore, `ranking`);
//  const docSnap = await getDocs(docRef);
//  const aux = [];
//  docSnap.forEach((docc)=>{
//    if(docc.data().pruebas.length === 0){
//      aux.push(docc.data());
//    }
//  })
//  
//  console.log(aux);
//  aux.forEach((a)=>{
//    const ti = doc(firestore, `ranking/${a.identificacion}`);
//    deleteDoc(ti);
//  })
//}
//limpiarRanking();

// ------------------ fin configuracion para limpiar el ranking ----------------------